import Vue from 'vue';
import Router from 'vue-router';
import * as client from "@/client/webclient";
import store from '@/store/index.js'

Vue.use(Router);

const routes = [{
    path: '/health',
    name: 'health',
    component: Vue.component('Health', {
        data() { return {} },
        render(createElement) {
            return createElement(
                'p', {}, ["Healthy"]
            );
        }
    }),
    meta: { title: 'Health - Moonfrog Distribution' },
},
{
    path: '/',
    name: 'signup',
    meta: { title: 'Sign Up - Moonfrog Distribution' },
    component: () =>
        import('../views/SignupNew.vue'),
},
{
    path: '/home',
    name: 'home',
    meta: { title: 'Home - Moonfrog Distribution' },
    component: () =>
        import('../views/HomeNew.vue'),
    beforeEnter: (to, from, next) => {
        const email = store.state.account.email;
        client.getUser(email).then((mfUser) => {
            if (!mfUser || (mfUser.userType !== 'RESELLER' && mfUser.userType !== 'SUBRESELLER' && mfUser.userType !== 'RETAILER') || mfUser.status !== 'ACTIVE') {
                alert('Invalid User');
                return next('/');
            } else next();
        }).catch(err => {
            alert(err);
            return next('/');
        })
    },
    props: true
},
// {
//     path: '/oldhome',
//     name: 'oldhome',
//     meta: { title: 'OLD HOME - Moonfrog Distribution' },
//     component: () =>
//         import('../views/Home.vue'),
//     beforeEnter: (to, from, next) => {
//         const email = store.state.account.email;
//         client.getUser(email).then((mfUser) => {
//             if (!mfUser || (mfUser.userType !== 'RESELLER' && mfUser.userType !== 'SUBRESELLER') || mfUser.status !== 'ACTIVE') {
//                 alert('Invalid User');
//                 return next('/');
//             } else next();
//         }).catch(err => {
//             alert(err);
//             return next('/');
//         })
//     },
//     props: true,
// },
// DO NOT DELETE THIS COMMENTED CODE !!! FOR EMERGENCY USAGE.
// {
//     path: '/reseller',
//     name: 'reseller',
//     meta: { title: 'My Account - Moonfrog Distribution' },
//     component: () =>
//         import ('../views/Reseller.vue'),
//     beforeEnter: (to, from, next) => {
//         const email = store.state.account.email;
//         client.getUser(email).then((mfUser) => {
//             if (!mfUser || mfUser.userType !== 'RESELLER' || mfUser.status !== 'ACTIVE') {
//                 alert('Invalid User');
//                 return next('/');
//             } else next();
//         }).catch(err => {
//             alert(err);
//             return next('/');
//         })
//     },
//     props: true
// },
{
    path: '/mfops',
    name: 'mfops',
    meta: { title: 'MF Ops Dashboard - Moonfrog Distribution' },
    component: () =>
        import('../views/MfOps.vue'),
    beforeEnter: (to, from, next) => {
        const email = store.state.account.email;
        client.getUser(email).then((mfUser) => {
            if (!mfUser || mfUser.userType !== 'MFOPS') {
                return next('/');
            } else next();
        }).catch(err => {
            alert(err);
            return next('/');
        })
    }
},
{
    path: '/account',
    name: 'reseller_rzp',
    meta: { title: 'My Account - Moonfrog Distribution' },
    component: () =>
        import('../views/Reseller_RZP.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
        const email = store.state.account.email;
        client.getUser(email).then((mfUser) => {
            if (!mfUser || (mfUser.userType !== 'RESELLER' && mfUser.userType !== 'SUBRESELLER' && mfUser.userType !== 'RETAILER') || mfUser.status !== 'ACTIVE') {
                alert('Invalid User');
                return next('/');
            } else next();
        }).catch(err => {
            alert(err);
            return next('/');
        })
    },
},
{
    path: '/paymentTnC',
    name: 'paymentTnC',
    meta: { title: 'Payment T&C - Moonfrog Distribution' },
    component: () =>
        import('../views/static/PaymentTnC.vue'),
    props: true,
},
{
    path: '/distributorTnC',
    name: 'distributorTnC',
    meta: { title: 'Distributor T&C - Moonfrog Distribution' },
    component: () =>
        import('../views/static/DistributorTnC.vue'),
    props: true,
},
{
    path: '/redemptionTnC',
    name: 'redemptionTnC',
    meta: { title: 'Redemption T&C - Moonfrog Distribution' },
    component: () =>
        import('../views/static/RedemptionTnC.vue'),
    props: true,
},
{
    path: '/network',
    name: 'myNetwork',
    meta: { title: 'My Network - Moonfrog Distribution' },
    component: () =>
        import('../views/MyNetwork.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
        const email = store.state.account.email;
        client.getUser(email).then((mfUser) => {
            if (!mfUser || !(mfUser.userType === 'RESELLER' || mfUser.userType === 'SUBRESELLER') || mfUser.status !== 'ACTIVE') {
                alert('Invalid User');
                return next('/');
            } else next();
        }).catch(err => {
            alert(err);
            return next('/');
        })
    },
},
{
    path: '/network/:email',
    name: 'SubReseller',
    meta: { title: 'Sub Reseller - Moonfrog Distribution' },
    component: () =>
        import('../views/SubReseller.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
        const email = store.state.account.email;
        client.getUser(email).then((mfUser) => {
            if (!mfUser || !(mfUser.userType === 'RESELLER' || mfUser.userType === 'SUBRESELLER') || mfUser.status !== 'ACTIVE') {
                alert('Invalid User');
                return next('/');
            } else next();
        }).catch(err => {
            alert(err);
            return next('/');
        })
    },
},
{
    path: '/referrals',
    name: 'myReferrals',
    meta: { title: 'My Referrals - Moonfrog Distribution' },
    component: () =>
        import('../views/MyReferrals.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
        const email = store.state.account.email;
        client.getUser(email).then((mfUser) => {
            if (!mfUser || (mfUser.userType !== 'RESELLER' && mfUser.userType !== 'SUBRESELLER' && mfUser.userType !== 'RETAILER') || mfUser.status !== 'ACTIVE') {
                alert('Invalid User');
                return next('/');
            } else next();
        }).catch(err => {
            alert(err);
            return next('/');
        })
    },
},
{
    path: '/referrals/:gameId/:playerId',
    name: 'myPlayer',
    meta: { title: 'My Player - Moonfrog Distribution' },
    component: () =>
        import('../views/MyPlayer.vue'),
    props(route) {
        return {
            playerId: route.query.playerId,
            gameId: route.query.gameId,
            gameName: route.query.gameName,
            creditsBought: route.query.amount,
            installDate: route.query.installDate,
            status: route.query.status,
        }
    },
    beforeEnter: (to, from, next) => {
        const email = store.state.account.email;
        client.getUser(email).then((mfUser) => {
            if (!mfUser || (mfUser.userType !== 'RESELLER' && mfUser.userType !== 'SUBRESELLER' && mfUser.userType !== 'RETAILER') || mfUser.status !== 'ACTIVE') {
                alert('Invalid User');
                return next('/');
            } else next();
        }).catch(err => {
            alert(err);
            return next('/');
        })
    },
},
{
    path: "/referral/:email",
    name: "Referral",
    meta: { title: "Referral Links" },
    component: () => import('../views/Referral.vue'),
},
{
    path: "/redeem",
    name: "Redeem",
    meta: { title: "Redeem Voucher" },
    component: () => import('../views/Redeem.vue'),
},
{
        path: "/store/:game?",
        name: "Store",
        meta: { title: "Store" },
        props :true,
        component: () => import('../views/StorePage_Home.vue'),
},
{ //To be written at the end and used for custom 404 errors.
    path: '*',
    name: 'pageDoesNotExist',
    component: () => import('../views/static/Error404.vue')
}
]

const router = new Router({
    mode: 'history',
    routes
});

const DEFAULT_TITLE = 'Moonfrog Distribution';
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
    if (to.path === '/' && from.path !== '/') {
        router.go();
    }
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/', '/mfops', '/reseller', '/home', '/account', '/distributorTnC', '/paymentTnC', '/health', '/network', '/referrals'];
    let authRequired = !publicPages.includes(to.path);
    if (to.path.includes('/referral') || to.path.includes('/redeem') || to.path.includes('/redemptionTnC') || to.path.includes('/store')) authRequired = false;
    const loggedIn = localStorage.getItem('access_token');
    if (authRequired && !loggedIn) {
        return next('/');
    }
    next();
})

export default router