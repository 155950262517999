const apiConfig = {
   // API_URL: 'http://localhost:3000/api', //For local machine
    // API_URL: 'https://mfl-sagegamma.moonfroglabs.com/api', //for dev VM
    // API_URL: 'https://mfl-sagebeta.moonfroglabs.com/api',
    // API_URL: 'https://mfl-sagealpha.moonfroglabs.com/api',
    // API_URL: 'https://mfl-prod-distribution.moonfroglabs.com/api', //for prod VM
    // API_URL: '/api', // for k8s
    installOS: 'mf_sage',
    gameId: "45",
    // STATS_URL: "http://a7b3a2da5c229454281e2a77bce79f23-1122549740.ap-south-1.elb.amazonaws.com/V1/45/stats/s",
    // STATS_URL: "https://mfl-badger-prod-statscluster.moonfroglabs.com/V1/45/stats/s",
    routes: {
        //distribution-tool api routes
        //Store api routes
        VERIFY_ZAIN_OTP:"/zain/payment/verify",
        CREATE_PAYMENT_ORDER:"/v1/checkout/payment",
        CHECK_PAYMENT_ORDER : "/v1/checkout/payment/status",
        SEND_OTP: '/onboarding/sendOTP',
        SUBMIT_DETAILS: '/onboarding/submitDetails',
        VERIFY_OTP: '/onboarding/verifyOTP',
        GRANT_PACKAGE: '/grantPackage',
        GET_GAME_PACKAGES: '/getGamePackages',
        GET_GAMES: '/getGames',
        VALIDATE_PID: '/validatePid',
        GET_RESELLER_CREDITS: '/getResellerWallet',
        GET_RESELLER_PAYMENT: '/purchase/reseller',
        CREATE_USER: '/createUser',
        GET_USER: '/getUser',
        GET_RESELLER: '/reseller',
        GET_MF_PACKAGES: '/getMFPackage', //TODO
        PURCHASE_PACKAGE: '/purchasePackage',
        GET_RESELLER_PLAYER_TRANSACTIONS: '/resell/user',
        UPDATE_STATUS: '/user/status',
        GET_ALL_RESELLERS: '/user/resellers',
        GET_ALL_PAYMENTS: '/purchase/all',
        APPROVE_PAYMENT: '/approveMFPackage',
        REJECT_PAYMENT: '/rejectMFPackage',
        GET_PAGED_PAYMENTS: '/purchase',
        GET_PAGED_RESELLERS: '/user/resellers',
        GET_PAGED_RESELLER_PAYMENT: '/purchase/resellers',
        MFOPS_GRANT: '/mfops/grant',
        MFOPS_GRANT_HISTORY: '/mfops/grants',
        USER_ALL_PAGED_TRANSACTIONS: '/user/transactions',
        GET_KYC_DATA: '/kycData',
        GET_SUBRESELLERS: '/reseller/subresellers',
        GET_SUBRESELLER_STATS: '/reseller/subreseller/stats',
        REQUEST_DEACTIVATION: '/reseller/status',
        DOWNLOAD: '/download',
        DOWNLOAD_KYC_CONTRACT: '/reseller/kyc/download',
        REQUEST_KYC: '/user/kyc/request',

        //Get packages
        GET_PACKAGES_FOR_PID: '/getPackagesForPID',

        // Referral Apis
        GET_REFERRAL_DATA: '/referral',
        GET_PLAYERS: '/referrals/players',

        // Payment Apis
        GET_CREDIT_EXCHANGE_RATE: '/payment/exchangeRate',
        GET_PAGED_RESELLER_RAZORPAY_PAYMENT: '/allGateways/payment/transactions',
        // Razorpay
        GET_RAZORPAY_KEY: "/razorpay/key",
        RAZORPAY: '/razorpay/payment',
        // Bkash
        CHECK_BKASH_PAYMENT_STATUS: '/bkash/payment/check',
        BKASH_PAYMENT_VERIFICATION: '/bkash/payment/verify',
        REFUND_BKASH_PAYMENT: '/bkash/payment/resolve',
        CREATE_BKASH_ORDER: '/bkash/payment',
        // Aamarpay
        CREATE_AAMARPAY_ORDER: '/aamarpay/payment',

        // Support Apis
        SUPPORT_GET_TICKETS: "/support/getUserTickets",
        SUPPORT_GET_COMMENTS: "/support/getTicketComments",
        SUPPORT_CREATE_TICKET: "/support/createTicket",
        SUPPORT_REPLY_TICKETS: "/support/replyToTicket",
        SUPPORT_CREATE_CUSTOMER: "/support/createCustomer",

        // Voucher Redemption
        REDEEM: "/voucher/redeem",

        // GiftCard Redemption
        GIFTCARD_VALIDATE: "/v1/checkout/giftcard/validate",
        GIFTCARD_REDEEM: "/v1/checkout/giftcard/redeem",
       // PARTIAL_GIFTCARD_CHECKOUT : "/v1/checkout/giftcard/redeem",
        GET_STORE_PACKAGES : "/v1/game/player/packages",
        GET_STORE_GAMES : "/v1/game/list",
        GET_STORE_CONFIG : "/store/display/config",
        GET_AVAILABLE_PAYMENTS : "/store/payment/list"
    }
}

export default apiConfig
