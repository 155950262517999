<template>
  <v-app class="App">
    <v-main class="vertical-center" style="background:rgba(26, 23, 77, 0.8)">
      <div class="inner-block">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
