import Vue from 'vue'
import Vuex from 'vuex';

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    account: {
      email: '',
      profilePic: '',
      userType: '',
      countryCode: ''
    },
    creditExchangeRate: {
      IN: 1,
      BD: 0
    },
    razorpayKey: ''
  },
  mutations: {
    setAccount(state, emailId) {
      state.account.email = emailId;
    },
    resetAccount(state) {
      state.account.email = "";
    },
    setProfilePic(state, imageUrl) {
      state.account.profilePic = imageUrl;
    },
    setUserType(state, userType) {
      state.account.userType = userType;
    },
    setCountryCode(state, countryCode) {
      state.account.countryCode = countryCode;
    },
    setCreditExchangeRate(state, creditExchangeRate) {
      state.creditExchangeRate = creditExchangeRate;
    },
    setRazorpayKey(state, key) {
      state.razorpayKey = key;
    }
  },
  actions: {
    saveResellerDetails(context, resellerDetails) {
      //Call backend API to save the reseller details
      console.log(resellerDetails);
    }
  },
  modules: {
  },
  plugins: [createPersistedState()],
});
export default store;