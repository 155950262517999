import axios from "axios"
import apiConfig from '../config/api.config';

export const sendOTP = async (mobileNumber) => {
    console.log("sendOTP webclient: " + mobileNumber);
    const requestObj = { mobileNumber: mobileNumber };
    let returnObj = '';
    await axios.post(process.env.VUE_APP_URL + apiConfig.routes.SEND_OTP, requestObj)
        .then(response => {
            //console.log(response);
            if (response.data === "success") {
                //OTP Sent
                //console.log("SUCCESS");
                returnObj = 'sent';
            } else {
                //OTP Send Error
                //console.log("ERROR");
                returnObj = 'failed';
            }
        })
        .catch(error => {
            //Error in sending OTP
            console.log(error);
            returnObj = 'error';
        });
    return returnObj;
}

export const verifyOTP = async (otp, mobileNumber) => {
    console.log("verifyOTP webclient: " + otp)
    const requestObj = { mobileNumber: mobileNumber, otp: otp };
    let returnObj = '';
    await axios.post(process.env.VUE_APP_URL + apiConfig.routes.VERIFY_OTP, requestObj)
        .then(response => {
            //console.log(response);
            if (response.data === "success") {
                //Check 
                //console.log("MATCHED");
                returnObj = 'matched';
            } else {
                //Could not verify
                //console.log("NOT MATCHED");
                returnObj = 'not-matched';
            }
        })
        .catch(error => {
            //Could not verify
            console.log(error);
            returnObj = 'error';
        });
    return returnObj;
}

export const submitDetails = async (resellerDetails) => {
    const requestObj = resellerDetails;
    await axios.post(process.env.VUE_APP_URL + apiConfig.routes.SUBMIT_DETAILS, requestObj)
        .then(response => {
            if (response.data.success) {
                //SUBMIT SUCCESS
            } else {
                //SUBMIT Failed
            }
        })
        .catch(error => {
            //SUBMIT Error
            console.log(error);
        });
}

export const createReseller = async (resellerDetails) => {
    // let config = {
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'multipart/form-data',
    //     }
    // }

    return new Promise((resolve, reject) => {
        //axios.post(process.env.VUE_APP_URL + apiConfig.routes.CREATE_USER, resellerDetails, config)
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.CREATE_USER, resellerDetails)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getUser = async (email) => {
    const payload = { emailId: email };
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GET_USER, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getReseller = async (email, requestorEmail) => {
    const payload = { emailId: email, requestorEmail: requestorEmail, access_token: localStorage.getItem("access_token") };
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GET_RESELLER, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getResellerList = async (page, requestorEmail) => {
    const payload = { access_token: localStorage.getItem("access_token"), requestorEmail: requestorEmail, page: page };
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GET_PAGED_RESELLERS, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getPurchaseTransactions = async (page) => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_PAGED_PAYMENTS, {
            params: {
                page: page
            }
        })
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getResellerName = async (email) => {
    const payload = { emailId: email };
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GET_USER, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result.name);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getMFPackages = async () => {
    const response = await axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_MF_PACKAGES);
    return new Promise((resolve) => {
        resolve(response.data);
    });
}

export const updateResellerStatus = async (email, status, requestorEmail, reason, gender = "", dateOfBirth = "", countryCode = "") => {
    const payload = {
        emailId: email,
        status: status,
        access_token: localStorage.getItem("access_token"),
        requestorEmail: requestorEmail,
        reason: reason,
        gender: gender,
        dob: dateOfBirth,
        countryCode: countryCode
    };
    return new Promise((resolve, reject) => {
        axios.put(process.env.VUE_APP_URL + apiConfig.routes.UPDATE_STATUS, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getGames = async (emailId, isStoreCust = false) => {
   
    const params = {
        requestorEmail: emailId,
        access_token: localStorage.getItem("access_token")
    }

    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_GAMES, { params })
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const validatePid = async (gameId, pid, resellerEmailID) => {

    const payload = { targetPid: pid, gameId: gameId, pidType: undefined, access_token: localStorage.getItem("access_token"), requestorEmail: resellerEmailID };
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.VALIDATE_PID, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const grantPackage = async (pid, packageDetails, priceReceived, amount, resellerId) => {

    const payload = {
        gameId: packageDetails.gameId,
        packageId: packageDetails.id,
        targetPid: pid,
        mfValue: packageDetails.priceInMfCredits,
        price: priceReceived,
        requestorEmail: resellerId,
        amount: amount,
        packageName: packageDetails.name,
        inventoryType: packageDetails.inventoryType,
        isFixedValue: packageDetails.isFixedValue,
        access_token: localStorage.getItem("access_token")
    };

    let source = axios.CancelToken.source();
    setTimeout(() => {
        source.cancel("E_TIMEOUT");
    }, 60000);

    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GRANT_PACKAGE, payload, { cancelToken: source.token })
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                if (error.message === "E_TIMEOUT") {
                    console.error("Connection Timeout, cancelling request");
                    reject("Error in connection. Please check the transaction status after internet is back");
                } else {
                    console.error(error);
                    if (error && error.response && error.response.data && error.response.data.errMsg) reject(error.response.data.errMsg)
                    else reject("Error in connection");
                }
            });
    });
}

export const getGamePackages = async (gameId, name, resellerEmailID) => {
    
    const payload = { gameId: gameId, name: name, access_token: localStorage.getItem("access_token"), requestorEmail: resellerEmailID };

    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GET_GAME_PACKAGES, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getPackagesForPID = async (targetPid, gameId, resellerEmailID, isStorePage = false) => {
    const payload = { targetPid: targetPid, gameId: gameId, access_token: localStorage.getItem("access_token"), requestorEmail: resellerEmailID };
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GET_PACKAGES_FOR_PID, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getMoonfrogCredits = async (resellerId, requestorEmail) => {

    const payload = { emailId: resellerId, access_token: localStorage.getItem("access_token"), requestorEmail: requestorEmail };

    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GET_RESELLER_CREDITS, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result.credits);
                } else {
                    reject(response.data.errMsg);
                }
            }).catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    })

    // const response = await axios.post(process.env.VUE_APP_URL + apiConfig.routes.GET_RESELLER_CREDITS, payload);
    // return new Promise((resolve) => {
    //     resolve(response.data.credits);
    // });
}

export const getResellerPlayerTransactions = async (resellerId, page, requestorEmail, dateRangeObj = {}, gameId = null, playerId = "") => {
    const payload = {
        emailId: resellerId,
        access_token: localStorage.getItem("access_token"),
        requestorEmail: requestorEmail,
        page: page,
        dateRange: dateRangeObj,
        gameId: gameId,
        playerId: playerId
    };

    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GET_RESELLER_PLAYER_TRANSACTIONS, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getResellerPaymentTransactions = async (resellerId, page) => {
    const params = {
        emailId: resellerId,
        page: page
    }
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_PAGED_RESELLER_PAYMENT, { params })
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const purchasePackage = async (paymentDetails) => {
    let config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    }
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.PURCHASE_PACKAGE, paymentDetails, config)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const approveMFPayment = async (id, requestorEmail) => {
    const payload = { id: id, requestorEmail: requestorEmail };
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.APPROVE_PAYMENT, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const rejectMFPayment = async (id, requestorEmail, rejectReason) => {
    const payload = { id: id, requestorEmail: requestorEmail, rejectReason: rejectReason };
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.REJECT_PAYMENT, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const grantMfcredits = async (emailId, mfValue, userType, reason, requestorEmail) => {
    const payload = {
        emailId: emailId,
        mfValue: mfValue,
        userType: userType,         // userType: MFOPS or RESELLER
        reason: reason,
        requestorEmail: requestorEmail,
        access_token: localStorage.getItem("access_token")
    };
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.MFOPS_GRANT, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const grantHistory = async (page, requestorEmail, userType, emailId = "empty", dateRangeObj = {}) => {
    let params = {
        page: page,
        emailId: emailId,
        userType: userType,     // userType: MFOPS or PARENT_RESELLER or ONE_RESELLER
        requestorEmail: requestorEmail,
        access_token: localStorage.getItem("access_token"),
        dateRange: dateRangeObj
    }
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.MFOPS_GRANT_HISTORY, {
            params: params
        })
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const userAllPagedTransactions = async (page, requestorEmail) => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.USER_ALL_PAGED_TRANSACTIONS, {
            params: {
                page: page,
                requestorEmail: requestorEmail,
                access_token: localStorage.getItem("access_token")
            }
        })
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getKYCData = async (emailId, requestorEmail) => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_KYC_DATA, {
            params: {
                emailId: emailId,
                requestorEmail: requestorEmail,
                access_token: localStorage.getItem("access_token")
            }
        })
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getKycContract = async (emailId, requestorEmail) => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.DOWNLOAD_KYC_CONTRACT, {
            params: {
                emailId: emailId,
                requestorEmail: requestorEmail,
                access_token: localStorage.getItem("access_token")
            }
        })
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const requestKYC = async (emailId, requestorEmail) => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.REQUEST_KYC, {
            params: {
                emailId: emailId,
                requestorEmail: requestorEmail,
                access_token: localStorage.getItem("access_token")
            }
        })
            .then(response => {
                if (response.data.success) {
                    resolve();
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getSubresellers = async (name, requestorEmail, page) => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_SUBRESELLERS, {
            params: {
                page: page,
                name: name,
                requestorEmail: requestorEmail,
                access_token: localStorage.getItem("access_token")
            }
        }).then(response => {
            if (response.data.success) {
                resolve(response.data.result);
            } else {
                reject(response.data.errMsg);
            }
        }).catch(error => {
            console.error(error);
            rejectWithErrorMsg(reject, error);
        });
    });
}

export const getSubresellerStats = async (emailId, requestorEmail) => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_SUBRESELLER_STATS, {
            params: {
                emailId: emailId,
                requestorEmail: requestorEmail,
                access_token: localStorage.getItem("access_token")
            }
        }).then(response => {
            if (response.data.success) {
                resolve(response.data.result);
            } else {
                reject(response.data.errMsg);
            }
        }).catch(error => {
            console.error(error);
            rejectWithErrorMsg(reject, error);
        });
    });
}

export const getPlayers = async (playerId, resellerId, gameId, requestorEmail, page) => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_PLAYERS, {
            params: {
                page: page,
                playerId: playerId,
                resellerId: resellerId,
                gameId: gameId,
                requestorEmail: requestorEmail,
                access_token: localStorage.getItem("access_token")
            }
        }).then(response => {
            if (response.data.success) {
                resolve(response.data.result);
            } else {
                reject(response.data.errMsg);
            }
        }).catch(error => {
            console.error(error);
            rejectWithErrorMsg(reject, error);
        });
    });
}

export const requestResellerDeactivation = async (resellerId, requestorEmail, reason) => {
    let payload = {
        emailId: resellerId,
        requestorEmail: requestorEmail,
        access_token: localStorage.getItem("access_token"),
        status: 'deactivate',
        reason: reason
    }
    return new Promise((resolve, reject) => {
        axios.put(process.env.VUE_APP_URL + apiConfig.routes.REQUEST_DEACTIVATION, payload)
            .then(response => {
                if (response.data.success) resolve();
                else reject(response.data.errMsg);
            }).catch(async (error) => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    })
}

export const getTransactionsExcel = async (resellerId, requestorEmail, type, dateRangeObj = {}, gameId = null, playerId = "") => {
    let params = {
        resellerId: resellerId,
        requestorEmail: requestorEmail,
        access_token: localStorage.getItem("access_token"),
        dateRange: dateRangeObj,
        gameId: gameId,
        playerId: playerId
    }
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.DOWNLOAD + '/' + type, {
            params: params,
            responseType: 'blob'
        }).then(response => {
            downloadFile(response.data);
            resolve();
        }).catch(async (error) => {
            if (error.response && error.response.data && error.response.data.type && error.response.data.type.toLowerCase().indexOf('json') != -1) {
                let responseText = await error.response.data.text();
                let data = JSON.parse(responseText);
                if (Object.prototype.hasOwnProperty.call(data, 'errMsg')) reject(data.errMsg);
            }
            reject("Error in connection");
        });
    })
}

export const getReferralData = async (email) => {
    return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_URL + apiConfig.routes.GET_REFERRAL_DATA + "/" + email;
        axios.get(url)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    })
}

export const initiateRedemption = async (playerId, voucherCode,selectedVoucherId) => {
    let params = {
        playerId: playerId,
        voucherCode: voucherCode,
        selectedVoucherId:selectedVoucherId
    }
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GIFTCARD_REDEEM,  params )
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}

function downloadFile(data) {
    const url = URL.createObjectURL(new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.xlsx');
    document.body.appendChild(link);
    link.click();
}

function rejectWithErrorMsg(reject, error) {
    if (error && error.response && error.response.data && error.response.data.errMsg) reject(error.response.data.errMsg)
    else reject("Error in connection");
}
