import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'

import { BootstrapVue, IconsPlugin, ImagePlugin } from "bootstrap-vue";

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(ImagePlugin);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vuetify from './plugins/vuetify';
import * as stats from "@moonfroglabs/mfl-stats-client-sdk";
import VueSocialSharing from 'vue-social-sharing'
import VueQRCodeComponent from 'vue-qr-generator'
import VueCountryCode from 'vue-country-code'
import VueMoment from 'vue-moment'

Vue.prototype.$stats = stats

library.add(faSpinner)

Vue.config.productionTip = false
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.use(VueSocialSharing);
Vue.use(VueCountryCode);
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(VueMoment);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')